


















































import Vue from 'vue'
import AdsManagerFilter from '@/components/ADSManager/MainComponent/AdsManagerFilter.vue'
import Header from '@/components/ADSManager/MainComponent/Header.vue'
import CampaignPerformances from '@/components/ADSManager/MainComponent/CampaignPerformancesAdsManagerMain.vue'
import TableAdsManager from '@/components/ADSManager/MainComponent/NewMainTableAdsManager.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
import { STATUS_UNAUTHENTICATED, STATUS_HASNT_PERMISSION } from '@/constants/AdAccount'

export default Vue.extend({
  name: 'ADSManager',
  components: {
    BaseAlert,
    Header,
    CampaignPerformances,
    TableAdsManager,
    AdsManagerFilter
  },
  data () {
    return {
      page: 1,
      loading: false,
      alert: false,
      adAccountId: null,
      dataChart: null,
      showAlert: false,
      noData: [
        {
          id: 0,
          showState: false,
          name: 'impressions',
          value: 'No Data',
          title: 'Impressions',
          optionsChart: {
            id: 0,
            name: 'impressions',
            data: [0],
            color: '#12B1A0'
          },
          categories: ['No Data']
        },
        {
          id: 1,
          showState: false,
          name: 'reach',
          value: 'No Data',
          title: 'Reach',
          optionsChart: {
            id: 1,
            name: 'reach',
            data: [0],
            color: '#fb9700'
          },
          categories: ['No Data']
        },
        {
          id: 2,
          showState: false,
          name: 'spend',
          value: 'No Data',
          title: 'Spend',
          optionsChart: {
            id: 2,
            name: 'spend',
            data: [0],
            color: '#b38135'
          },
          categories: ['No Data']
        },
        {
          id: 3,
          showState: false,
          name: 'clicks',
          value: 'No Data',
          title: 'Clicks',
          optionsChart: {
            id: 3,
            name: 'clicks',
            data: [0],
            color: '#fc355f'
          },
          categories: ['No Data']
        },
        {
          id: 4,
          showState: false,
          name: 'cpm',
          value: 'No Data',
          title: 'CPM',
          optionsChart: {
            id: 4,
            name: 'cpm',
            data: [0],
            color: '#38acff'
          },
          categories: ['No Data']
        }
      ],
      statuses: {
        ACTIVE: 'Active',
        ARCHIVED: 'Archived',
        BUILDING: 'In Draft',
        COMPLETED: 'Completed',
        DELETED: 'Deleted',
        ERROR: 'Error',
        PAUSED: 'Paused',
        READY_FOR_FB_CREATING: 'Publishing',
        UPDATING: 'Updating'
      },
      displayedFields: {
        impressions: 'Impressions',
        reach: 'Reach',
        spend: 'Spend',
        clicks: 'Clicks',
        uniqueClicks: 'Engagement clicks',
        frequency: 'Frequency',
        ctr: 'CTR',
        cpm: 'CPM',
        cpc: 'CPC',
        cpp: 'CPP'
      }
    }
  },

  created () {
    const dateRange = JSON.parse(localStorage.getItem('dateRangeMainAdsManager'))
    if (dateRange) {
      this.periodChange(dateRange)
    }
    this.page = localStorage.getItem('pageMainAdsManager') || 1
  },
  computed: {
    ...mapGetters('adsManager', ['getCampaigns']),
    ...mapGetters('filter', ['getFilterPayload']),
    ...mapGetters('adAccounts', {
      getAdAccountById: 'getById'
    })
  },

  methods: {
    ...mapActions('adsManager', ['get_campaign', 'get_campaign_targeting', 'load_campaigns']),
    ...mapMutations('filter', ['setDateFilters']),
    closeAlert () {
      this.showAlert = false
    },

    // *формируем массив объектов для передачи в компоненту с графиками
    getDataChart (periods, selectedPeriod = 'day') {
      const result = []
      this.dataChart = null
      // *с бэка day приходит как объект с объектами, а total/month/week как массив с объектами, поэтому форматируем исходную пачку данных корректной работый скрипта
      const formatPeriods = {
        day: {},
        week: {},
        total: {},
        month: {}
      }
      for (const periodKey in periods) {
        if (periodKey === 'day') {
          formatPeriods.day = periods.day
        } else {
          periods[periodKey].forEach((week) => {
            const keys = Object.keys(week)
            formatPeriods[periodKey][keys[0]] = week[keys[0]]
          })
        }
      }

      // * значения определенного инсайда по выбранному периоду
      const apexChartsInsightsValues = {
        impressions: [],
        reach: [],
        spend: [],
        clicks: [],
        uniqueClicks: [],
        frequency: [],
        ctr: [],
        cpm: [],
        cpc: [],
        cpp: []
      }
      const categories = []

      // * пробегаемся по объектам выбранного периода
      for (const currentPeriodKey in formatPeriods[selectedPeriod]) {
        const currentPeriodObj = formatPeriods[selectedPeriod][currentPeriodKey]
        // * пробегаемся по инсайдам текущего объекта и пушим их в массив с данными по инсайдам
        for (const insightName in currentPeriodObj) {
          if (this.displayedFields[insightName]) {
            apexChartsInsightsValues[insightName].push(currentPeriodObj[insightName])
          }
        }
        categories.push(currentPeriodObj?.date_start)
      }

      for (const insightName in apexChartsInsightsValues) {
        if (apexChartsInsightsValues[insightName].length) {
          result.push({
            id: result.length,
            showState: true,
            name: insightName,
            value: Math.round(apexChartsInsightsValues[insightName].reduce((acc, cur) => acc + cur)),
            title: this.displayedFields[insightName],
            optionsChart: {
              id: result.length,
              name: insightName,
              data: apexChartsInsightsValues[insightName]
            },
            categories: categories
          })
        }
      }
      if (periods.week.length) this.dataChart = result
      else this.dataChart = this.noData
    },

    getCampaignsApi (): void {
      if (this.adAccountId !== null) {
        this.loading = true
        this.load_campaigns({
          ad_account_id: this.adAccountId,
          filters: this.getFilterPayload,
          page: this.page || 1
        }).then(() => {
          this.loading = false
          this.alert = false
          this.getDataChart(this.getCampaigns.meta.periods)
        }).catch(() => {
          this.loading = false
          this.alert = true
        })
      }
    },

    changeAdAccount (id: string): void {
      this.adAccountId = id
      localStorage.setItem('pageMainAdsManager', '1')
      this.page = 1
      this.getCampaignsApi()
      const status = this.getAdAccountById(this.adAccountId)?.status
      if (status === STATUS_UNAUTHENTICATED || status === STATUS_HASNT_PERMISSION) {
        this.showAlert = true
      }
    },

    periodChange (date): void {
      this.setDateFilters([
        {
          field: 'START_DATE',
          value: date[0],
          predicate: null
        },
        {
          field: 'STOP_DATE',
          value: date[1],
          predicate: null
        }
      ])
      this.getCampaignsApi()
    },

    switchPage (page): void {
      this.page = page
      this.getCampaignsApi()
    }
  }
})
