<template>
  <div class="header_container">
    <div class="ads_block_buttons ">
      <div class="select_container">
        <AdAccountSelect
          v-if="!loading"
          :loading="loadingAdAccounts"
          :mountNotOption="true"
          @choose="selectedAccount => chooseAdAccount(selectedAccount)"
          :options="getAdAccounts"
          :option="selectedAdAccount"
          :itemKey="'label'"
          class="AdAccountSelect"
        />
        <div class="AdAccountSelectPreloader" v-else/>
      </div>
    </div>
    <div class="ads_button_container">
      <BaseDatePicker @periodChange="periodChange"
                      :timezone="timezone"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import 'vue2-datepicker/index.css'
import BaseDatePicker from '@/components/BaseComponents/BaseDatePicker/BaseDatePicker.vue'
import AdAccountSelect from '@/components/AdAccountDropdown.vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Header',
  components: {
    AdAccountSelect,
    BaseDatePicker
  },
  data () {
    return {
      selectedAdAccount: { label: 'Select Ad Account', value: null },
      loadingAdAccounts: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters('adAccounts', ['getById']),
    ...mapGetters('adsManager', ['getSelectedAdAccount']),
    getAdAccounts () {
      return this.getAllAccounts().map(account => {
        return { label: account.name, value: account.id }
      })
    },
    timezone () {
      return this.getSelectedAdAccount?.timezone
    }
  },
  mounted () {
    const local = localStorage.getItem('dateRangeMainAdsManager')
    const range = local && JSON.parse(local)
    if (range) {
      this.$emit('periodChange', range)
    }
  },
  created () {
    if (this.$store.state.adService.service !== 'facebook') {
      this.setService('facebook')
    }
    const adAccount = this.getSelectedAdAccount
    if (adAccount) {
      this.selectedAdAccount = { label: adAccount.name, value: adAccount.id }
    }
    this.loadAccounts({ search: { target: 'ads_management' } })
      .then(() => {
        this.loading = false
        this.loadingAdAccounts = false
        const saveFilters = JSON.parse(localStorage.getItem('filtersMainAds'))
        const all = this.getAdAccounts
        const selectId = localStorage.getItem('selectedAdAccount')
        if (saveFilters === null || saveFilters.length === 0) {
          if (selectId) {
            const account = all.find((element) => {
              if (element.value === selectId) {
                return element
              }
            })
            if (account) {
              this.chooseAdAccount(account)
              this.selectedAdAccount = account
            } else {
              this.chooseAdAccount(all[0])
              this.selectedAdAccount = all[0]
            }
          } else {
            this.chooseAdAccount(all[0])
            this.selectedAdAccount = all[0]
          }
        }
      })
  },
  methods: {
    ...mapGetters('adAccounts', ['getAllAccounts']),
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapActions('adService', ['setService']),
    ...mapActions('adsManager', ['set_selected_ad_account']),

    // * выбор ad account
    chooseAdAccount (selectedAccount) {
      if (typeof selectedAccount !== 'string') {
        this.$emit('selectedAdAccount', selectedAccount.value)
        this.set_selected_ad_account(this.getById(selectedAccount.value))
        localStorage.setItem('selectedAdAccount', selectedAccount.value)
      }
    },

    periodChange (range) {
      localStorage.setItem('dateRangeMainAdsManager', JSON.stringify(range))
      this.$emit('periodChange', range)
    }
  }
})
</script>

<style scoped lang="scss">
.header_container {
  min-height: 82px;
  min-width: 550px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 19px; // minus 1px of border
  // margin-top: 23px;
  // box-shadow: 1px 1px 2px #00000044; // rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 2px #d6dadf; // old box shadow
  border-radius: 3px;
  background: #fff;
  // border: 1px solid #EAEBEE;
  .ads_block_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 638px;
  }

  .select_container {
    // height: 54px;
    max-width: 290px;
  }

  .ads_button_container {
    display: flex;
    justify-content: flex-end;
    width: 190px;
  }
}
.facebook-icon {
  height: 32px;
  width: 32px;
}
.AdAccountSelectPreloader {
  height: 54px;
  min-width: 250px;
  background: #f2f3f7;
  border-radius: 4px;
  border: 1px solid #f2f3f7;
}
</style>
