





























































import Vue from 'vue'
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue'

export default Vue.extend({
  name: 'Columns',
  components: { BaseDropdown },
  props: {
    open: Boolean,
    chartsSlice: {
      type: Array
    },
    list: Array
  },
  methods: {}
})
