









































































import Vue from 'vue'
import Columns from '@/components/ADSManager/MainComponent/ColumnsAdsManagerMain.vue'
import Chart from '@/components/ADSManager/MainComponent/Charts/Chart.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'CampaignPerformances',
  components: { Chart, Columns, VSelect },
  props: {
    title: String,
    hideButton: Boolean,
    lastUpdate: String,
    dataChart: Array
  },

  data () {
    return {
      open: true,
      colors: ['#12B1A0', '#fb9700', '#b38135', '#fc355f', '#38acff'],
      optionsPeriods: [
        {
          label: 'Daily',
          code: 'day'
        },
        {
          label: 'Weekly',
          code: 'week'
        },
        {
          label: 'Monthly',
          code: 'month'
        }
      ]
    }
  },
  mounted () {
    this.chartsSlice.forEach((chart, index) => {
      chart.optionsChart.color = this.colors[index]
    })
  },

  computed: {
    chartsSlice: function () {
      return this.campaignPerformanceOrderedTitleSortedTrimmed(this.dataChart)
    },
    ...mapGetters('adsManager', ['getSelectedAdAccount']),
    timezone () {
      return this.getSelectedAdAccount?.timezone
    }
  },

  methods: {
    campaignPerformanceOrderedTitleSortedTrimmed (campaignPerformanceOrdered) {
      if (campaignPerformanceOrdered) {
        return campaignPerformanceOrdered
          .sort(function (a, b) {
            if (a.id > b.id) return 1
            if (a.id < b.id) return -1
            return 0
          })
          .slice(0, 5)
      }
      return []
    },

    checkShowState () {
      const showStateTrue = Object.assign([], this.chartsSlice).filter(
        chart => chart.showState === true
      )
      return showStateTrue
    },

    changeColumns (columnId, itemId, index) {
      this.dataChart.forEach(chart => {
        if (chart.id === columnId) {
          chart.id = itemId
          chart.optionsChart.color = this.colorConverter(index)
          this.dataChart[+itemId].id = columnId
        }
      })
      this.campaignPerformanceOrderedTitleSortedTrimmed(this.dataChart)
    },

    select (column) {
      this.chartsSlice.forEach((chart, index) => {
        chart.optionsChart.color = this.colors[index]
        if (column.id === chart.id) {
          chart.showState = !chart.showState
        }
      })
      this.checkShowState()
    },

    selectEvent (name) {
      const chart = this.chartsSlice.find(chart => chart.name === name)

      if (chart) {
        this.select(chart)
      }
    },

    colorConverter (index) {
      const chooseColor = index => {
        switch (index) {
          case 0:
            return '#12B1A0'
          case 1:
            return '#fb9700'
          case 2:
            return '#b38135'
          case 3:
            return '#fc355f'
          case 4:
            return '#38acff'
        }
      }

      return chooseColor(index)
    },

    selectedDate (selected) {
      this.$emit('periodChart', selected.code)
    }
  }
})
