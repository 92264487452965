



















































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'AdAccountDropdown',
  data () {
    return {
      text: 'TEXT',
      selected: '',
      dropdownState: false,
      value: this.option
    }
  },
  components: {},
  props: {
    options: {
      type: Array,
      required: true
    },
    deactivated: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    error: {
      type: Boolean,
      default: false
    },
    itemKey: {
      type: String,
      required: true
    },
    mountNotOption: {
      type: Boolean
    },
    option: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('adService', { service: 'getService', getServiceTitle: 'getServiceTitle', getServiceIconSrc: 'getServiceIconSrc' }),
    disabled () {
      return !this.options.length || this.deactivated
    }
  },
  mounted () {
    // this.$emit('choose', this.value)
    this.value = this.option
  },
  methods: {
    toggleOptions () {
      if (this.options.length > 0) {
        this.dropdownState = !this.dropdownState
      }
    },
    choose (index) {
      this.value = this.options[index]
      this.close()
      this.$emit('choose', this.value)
    },
    close () {
      this.dropdownState = false
    }
  }
})
