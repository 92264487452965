




















import Vue from 'vue'
export default Vue.extend({
  props: {
    src: { type: String, required: true },
    desc: { type: String, required: false },
    type: { type: String, required: false, default: 'test' },
    indicator: { type: Boolean, required: false },
    disabled: { type: Boolean }
  }
})
