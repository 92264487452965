











































import Vue from 'vue'
import StandartBtnText from '@/components/BaseComponents/BaseButton/BaseButton.vue'
import StandartBtnAction from '@/components/ADSManager/StandartBtnAction.vue'
import Select from '@/components/BaseComponents/BaseSelect/SelectTableColumns.vue'
import ModalConfirmDelete from '@/components/BaseComponents/BaseModalDeleteConfirm/ModalConfirmDelete.vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  components: {
    StandartBtnText,
    StandartBtnAction,
    Select
  },
  props: {
    campaign: Boolean,
    options: { type: Array, required: true },
    campaignId: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    ...mapActions('adsManager', ['delete_campaign', 'update_status_campaign']),
    ...mapActions('notifications', ['addNotification']),
    ...mapActions('adsManager/adDesign', ['reset_ad_design_data']),
    runCampaign () {
      this.update_status_campaign({
        status: 'run',
        ids_campaign: this.campaignId,
        ids_ad_set: [],
        ids_ad: []
      }).then(() => {
        this.addNotification({ body: 'Success', type: 'success' })
        this.$emit('getCampaign')
      }).catch(error => {
        this.addNotification({ body: error?.response?.data?.errors[0]?.detail[0], type: 'danger' })
      })
    },
    getText (campaignLength) {
      return campaignLength > 1
        ? 'Are you sure you want to delete this campaigns?'
        : 'Are you sure you want to delete this campaign?'
    },
    getTitle (campaignLength) {
      return campaignLength > 1
        ? 'Delete campaigns'
        : 'Delete campaign'
    },
    stopCampaign () {
      this.update_status_campaign({
        status: 'pause',
        ids_campaign: this.campaignId,
        ids_ad_set: [],
        ids_ad: []
      }).then(() => {
        this.addNotification({ body: 'Success', type: 'success' })
        this.$emit('getCampaign')
      }).catch(error => {
        this.addNotification({ body: error?.response?.data?.errors[0]?.detail[0], type: 'danger' })
      })
    },
    openModal () {
      this.$modal.show(ModalConfirmDelete,
        {
          title: this.getTitle(this.campaignId.length),
          text: [this.getText(this.campaignId.length)],
          delete: this.deleteConfirmedCampaign
        },
        {
          shiftY: 0.2,
          name: 'delete-campaign-modal',
          overlayTransition: 'linear',
          height: 'auto',
          width: '700',
          adaptive: true
        }
      )
    },
    deleteConfirmedCampaign () {
      this.delete_campaign({
        ids_campaign: this.campaignId,
        ids_ad_set: [],
        ids_ad: []
      }).then(() => {
        this.addNotification({ body: 'Success', type: 'success' })
        this.$emit('getCampaign')
        this.$modal.hide('delete-campaign-modal')
      })['catch'](error => {
        this.addNotification({ body: error?.response?.data?.errors[0]?.detail[0], type: 'danger' })
      })
    },
    goToNewCampaign () {
      this.reset_ad_design_data()
      this.$router.push({ name: 'NewCampaign' })
    }
  }
})
