


































































































































import Vue from 'vue'
import HeaderButtons from '@/components/ADSManager/MainComponent/TableHeaderButtons.vue'
import BasePagination from '@/components/BaseComponents/BasePagination/BasePagination.vue'
import Switcher from '@/components/Switcher.vue'
import CampaignInsights from '@/components/ADSManager/MainComponent/CampaignInsights.vue'
import { mapActions, mapGetters } from 'vuex'
import BasePagePreloader from '@/components/BasePagePreloader.vue'

export default Vue.extend({
  name: 'MainTableAdsManager',
  components: {
    BasePagePreloader,
    BasePagination,
    Switcher,
    HeaderButtons,
    CampaignInsights
  },

  methods: {
    ...mapActions('adsManager', ['update_status_campaign']),
    ...mapActions('notifications', ['addNotification']),

    campaign_editable (campaign) {
      return campaign.status === 'BUILDING' || campaign.status === 'ERROR'
    },
    campaignEdit (campaign) {
      return ['ACTIVE', 'PAUSED', 'WITH_ISSUES', 'FB_ERROR', 'COMPLETED'].includes(campaign.status)
    },
    go_to_edit (campaign_id) {
      this.$router.push({
        name: 'EditCampaign',
        params: { campaign_id: campaign_id }
      })
    },

    //  * ставим чекбокс  / убираем чекбоксы на все компании
    handleSelectAll (): void {
      this.campaignIds = []
      this.getCampaigns.data.forEach(campaign => {
        if (!this.isChecked) {
          campaign.isSelected = true
          this.campaignIds.push(+campaign.id)
        } else if (this.isChecked) {
          this.campaignIds = []
          campaign.isSelected = false
        }
      })
    },
    // * Берем нужные данные компании, если юзер ставит чекбокс на компанию
    giveDataCampaign (idCampaign: string, isSelected: boolean) {
      const checkEntryId = (): boolean => {
        return this.campaignIds.some(
          (currentId: string) => +currentId === +idCampaign
        )
      }

      if (!checkEntryId() && isSelected) {
        this.campaignIds.push(+idCampaign)
      }

      if (!isSelected) {
        this.campaignIds.forEach((id: string, index: number) => {
          if (+id === +idCampaign) {
            this.campaignIds.splice(index, 1)
          }
        })
      }
    },

    // * отдаем родителю страничку, на которую перешли, чтобы отправить запрос на получение
    async switchPage (pageNum: string) {
      localStorage.setItem('pageMainAdsManager', pageNum)
      this.$emit('switchPage',
        pageNum || 1
      )
    },

    goAllAds (id: string) {
      this.$router.push({
        params: { campaign_id: id },
        name: 'AllAds'
      })
    },

    // *  запрос на запуск компании
    runCampaign (idCampaign: string) {
      this.update_status_campaign({
        status: 'run',
        ids_campaign: idCampaign ? [+idCampaign] : this.campaignIds,
        ids_ad_set: [],
        ids_ad: []
      }).then(() => {
        this.addNotification({ body: 'Success', type: 'success' })
        this.$emit('getCampaignRequest')
      })['catch'](error => {
        this.addNotification({ body: error?.response?.data?.errors[0]?.detail[0], type: 'danger' })
      })
    },

    // *  запрос на паузу компании
    stopCampaign (idCampaign: string) {
      this.update_status_campaign({
        status: 'pause',
        ids_campaign: idCampaign ? [+idCampaign] : this.campaignIds,
        ids_ad_set: [],
        ids_ad: []
      }).then(() => {
        this.addNotification({ body: 'Success', type: 'success' })
        this.$emit('getCampaignRequest')
      })['catch'](error => {
        this.addNotification({ body: error?.response?.data?.errors[0]?.detail[0], type: 'danger' })
      })
    },
    disabledSwitcher (status: string) {
      const statuses = ['ACTIVE', 'PAUSED', 'COMPLETED']
      return !statuses.includes(status)
    }
  },
  mounted () {
    this.$emit('switchPage',
      this.page + (localStorage.getItem('pageMainAdsManager') || 1)
    )
  },

  props: {
    getCampaigns: {
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean
    },
    alert: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters('adsManager', [
      'getFilter',
      'getCampaignStatuses'
    ]),
    ...mapGetters('adAccounts', ['getAllAccounts'])
  },

  data () {
    return {
      isChecked: false,
      componentKey: 0,
      campaignIds: [],
      currency: null,
      coefficient: null,
      optionsColumns: [
        'Status',
        'Delivery',
        'Created in',
        'Start',
        'End',
        'Budget',
        'Ads',
        'Impr',
        'Clicks',
        'Spent',
        'Freq',
        'Reach',
        'CTR',
        'CPM'
      ]
    }
  }
})
