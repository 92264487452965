<template>
  <div class="chart_container">
    <apexcharts
      width="100%"
      height="300"
      :type="'line'"
      :options="chartOptions"
      :series="series"
    >
    </apexcharts>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'Chart',
  components: {
    apexcharts: VueApexCharts
  },
  props: {
    colors: [],
    options: Array,
    propName: String,
    addItem: Object,
    categories: Array
  },
  watch: {
    options () {
      this.updateChart()
    },
    categories () {
      this.updateChart()
    }
  },
  methods: {
    updateChart () {
      this.series = this.options
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.categories
          }
        }
      }
    },
    selectEvent (name) {
      this.$emit('selectEvent', name)
    }
  },
  data () {
    return {
      chartOptions: {
        chart: {
          id: 'basic-bar',
          zoom: {
            enabled: true
          },
          toolbar: {
            show: true
          },
          redrawOnParentResize: true,
          redrawOnWindowResize: true,
          events: {
            legendClick: undefined
          }
        },
        colors: ['#12B1A0', '#fb9700', '#b38135', '#fc355f', '#38acff'],
        legend: {
          show: true,
          showForSingleSeries: false,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: 14,
          labels: {
            colors: '#727272'
          },
          markers: {
            width: 16,
            height: 10,
            radius: 0
          }
        },
        dataLabels: {
          enable: false
        },
        stroke: {
          show: true,
          lineCap: 'butt',
          colors: undefined,
          width: 1,
          dashArray: 0
        },
        markers: {
          size: 4,
          strokeWidth: 0
        },
        grid: {
          borderColor: '#EAEAEA'
        },
        xaxis: {
          categories: this.categories
        },
        yaxis: {
          axisBorder: {
            show: true,
            color: '#CFCFCF',
            offsetX: 0,
            offsetY: 0
          }
        }
      },
      series: this.options
    }
  },
  mounted () {
    const selectEvent = this.selectEvent

    this.chartOptions.chart.events.legendClick = function (chartContext, seriesIndex, config) {
      selectEvent(config.config.series[seriesIndex]?.name)
    }
  }
}
</script>

<style scoped lang="scss">
.chart_container {
  width: 100%;
  // padding-right: 20px;
  margin-top: -5px;
  .apexcharts-canvas.apexchartsbasicxbar.apexcharts-theme-light {
    .apexcharts-legend.apexcharts-align-left.position-top {
      // margin-left: 35px;
    }
  }
}
</style>
