






import ADSManager from '@/components/ADSManager/AdsManager.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'AdsManager',
  components: {
    ADSManager
  }
})
