





























































































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'CampaignInsights',
  props: {
    campaign: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters('adsManager', [
      'getFilter',
      'getCampaignStatuses'
    ]),
    ...mapGetters('adAccounts', ['getAllAccounts']),
    impressions () {
      return this.campaign.insights?.impressions
    },
    clicks () {
      return this.campaign.insights?.clicks
    },
    spend () {
      return this.campaign.insights?.spend
    },
    frequency () {
      return this.campaign.insights?.frequency
    },
    reach () {
      return this.campaign.insights?.reach
    },
    ctr () {
      return this.campaign.insights?.ctr
    },
    cpm () {
      return this.campaign.insights?.cpm
    }
  },
  methods: {
    campaign_status_icon (campaign) {
      return (
        'campaign-status-icon campaign-status-icon-' +
        campaign.status.toLowerCase()
      )
    },
    chooseBudget (
      daily: number | null,
      lifetime: number | null,
      adAccountId: string
    ) {
      this.getAllAccounts.forEach(account => {
        if (Number(account.id) === Number(adAccountId)) {
          this.currency = account.currency
          this.coefficient = account.currency_factor
        }
      })
      if ((daily !== null || lifetime !== null) && this.coefficient !== null) {
        return `${(lifetime || daily) / this.coefficient} ${this.currency}`
      } else {
        return 'Using ad set budget'
      }
    }
  }
})
